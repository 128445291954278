import React from 'react'
import HeroGradient from 'components/hero-image/hero-image-gradient'
import { Helmet } from 'react-helmet'
import Promos from 'components/promos'
import Floater from 'components/floating-grid'
import Copy from 'components/category/copy'
import Content from 'components/category/content'
import Layout from 'components/layout'
import { graphql, Link } from 'gatsby'

const items = [
  {
    link: '/lawn-garden/lawn-tractors/',
    imageId: 'x500Image',
    name: 'Lawn Tractors',
    alt: 'John Deere Lawn Tractors',
  },
  {
    link: '/lawn-garden/residential-zero-turns/',
    imageId: 'z300Image',
    name: 'Zero Turns',
    alt: 'John Deere Residential Zero Turns',
  },
]

const ResidentialMowersPage = ({ data }) => {
  const { allHutsonPromotion, heroImage } = data
  const promos = []
  if (allHutsonPromotion) {
    allHutsonPromotion.nodes.forEach(node => promos.push(node))
  }
  const gridItems = items.map(item => {
    item.image = data[item.imageId]
    return item
  })
  return (
    <Layout>
      <Helmet>
        <title>John Deere Residential Mowers | Hutson Inc</title>
        <meta
          name='description'
          content='Looking for the perfect lawn mower to take care of your yard? Check out the full line of John Deere lawn tractors and zero-turn mowers at Hutson.'
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'Lawn & Garden Equipment',
                'item': 'https://www.hutsoninc.com/lawn-garden/',
              },
              {
                '@type': 'ListItem',
                'position': 3,
                'name': 'Residential Mowers',
                'item': 'https://www.hutsoninc.com/lawn-garden/residential-mowers/',
              },
            ],
          })}
        </script>
      </Helmet>

      <HeroGradient image={heroImage} heading='Residential Mowers' />

      <Content>
        <Floater items={gridItems} />

        <Copy header='John Deere Residential Mowers'>
          <p>
            If you're looking for a better yard and more comfort out of your mower then you're in
            the right place. Make your neighbor green with envy on the{' '}
            <Link to='/lawn-garden/lawn-tractors/'>Lawn Tractor Series</Link>, packing the most
            comfort and highest quality cut on the market. Or if you are looking for something to
            save a little time, try out our{' '}
            <Link to='/lawn-garden/residential-zero-turns/'>Zero Turn Mowers</Link>. The ease of
            driving and maneuverability of these machines will speed up your mowing and give you
            more time to enjoy your pristine property. Can't decide between a zero-turn and a riding
            mower? Read our{' '}
            <Link to='/blog/riding-mower-vs-zero-turn/'>blog post comparing the two</Link> to find
            the right fit.
          </p>
        </Copy>

        <Promos data={promos} type='Residential Mower' />
      </Content>
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    allHutsonPromotion(
      filter: {
        categoryList: {
          elemMatch: {
            category: { regex: "/residential-zero-turns|lawn-tractors/" }
            type: { eq: "john-deere" }
          }
        }
      }
      sort: { fields: [endDate], order: DESC }
      limit: 3
    ) {
      nodes {
        ...HutsonPromo
      }
    }
    heroImage: file(relativePath: { eq: "headers/residential-mowers-header.jpg" }) {
      ...FullWidthImage
    }
    x500Image: file(relativePath: { eq: "lawn-and-garden/x500-series.jpg" }) {
      ...FloatingGridImage
    }
    z300Image: file(relativePath: { eq: "lawn-and-garden/z300-series.jpg" }) {
      ...FloatingGridImage
    }
  }
`

export default ResidentialMowersPage
